import { Observable, throwError } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { catchError, delay, map } from 'rxjs/operators';
import { toCpoChargePoint } from '../converter/charger/ToCharger';
import { toCpoCreateChargePointRequestDto, toCpoUpdateChargePointDto, toUpdateConnectorTariffRequestDto, toUpdateCpoEvsesRequestDto } from '../converter/charger/ToChargerDto';
import { toChargerLogs } from '../converter/charger/ToChargerLog';
import { toCpoLocation } from '../converter/location/ToLocation';
import { toCpoLocationDto } from '../converter/location/ToLocationDto';
import { Osp, OspDto } from '../types/Osp';
import { CpoChargePoint, CpoConnector, NewCpoChargePoint } from '../types/charger/Charger';
import { CpoChargePointDto } from '../types/charger/ChargerDto';
import { ChargerLogs, DateRange, LogType } from '../types/charger/ChargerLogs';
import { CpoLocation, EmspLocation } from '../types/location/Location';
import { withAccessToken } from '../utils/auth/authenticationUtils';
import appConfig from '../utils/config/appConfig';
import { errorMessageObservable } from '../utils/error/errorUtils';
import { handleError } from '../utils/error/handleApiError';
import { buildUrlWithQueryString } from '../utils/url/buildQueryString';
import { toOsp } from '../converter/location/ToOsp';

const cpoAdminService = {
  getLocationById: (locationId: string): Observable<CpoLocation> => {
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.csCpoAdmin}/locations/${locationId}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((response) => toCpoLocation(response.response)),
      catchError((error) => handleError(error))
    );
  },

  updateLocation: (cpoLocationId: string, location: Partial<CpoLocation>): Observable<CpoLocation> => {
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.csCpoAdmin}/locations/${cpoLocationId}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'PATCH',
        body: toCpoLocationDto(location)
      }).pipe(
        delay(2000), // Add 2 seconds delay in hopes emsp location is also updated before we fetch it
        catchError((error) => throwError(new Error(error.response.message)))
      )
    );
  },

  getOcpiServiceProviders: (): Observable<Osp[]> => {
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.csCpoAdmin}/ocpi-service-providers/roaming`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'GET'
      }).pipe(
        map((response) => response.response.data.map((ospDto: OspDto) => toOsp(ospDto))),
        catchError((error) => throwError(new Error(error.response.message)))
      )
    );
  },
  createLocation(location: EmspLocation): Observable<CpoLocation> {
    const createLocation = {
      ...location,
      mspCountryCode: 'NZ',
      mspPartyId: 'CTY',
      timeZone: 'Pacific/Auckland',
      country: 'NZL'
    };
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.csCpoAdmin}/locations`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'POST',
        body: toCpoLocationDto(createLocation)
      }).pipe(
        map((response) => response.response),
        catchError((error) => throwError(new Error(error)))
      )
    );
  },

  getChargerByDisplayName(locationId: string, displayName: string): Observable<CpoChargePoint[]> {
    const url = buildUrlWithQueryString(`${appConfig.csCpoAdmin}/locations/${locationId}/charge-points`, { physical_reference: displayName });
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      })
    ).pipe(
      map((observable) => {
        return observable.response.data.map((charger: CpoChargePointDto) => toCpoChargePoint(charger));
      }),
      catchError((error) => errorMessageObservable(error))
    );
  },

  updateCharger(chargerPointId: string, ocppChargePointIdentifier: string, anonymousChargingAllowed: boolean): Observable<void> {
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.csCpoAdmin}/charge-points/${chargerPointId}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'PATCH',
        body: toCpoUpdateChargePointDto({
          ocppChargePointIdentifier,
          anonymousChargingAllowed
        })
      }).pipe(catchError((error) => throwError(new Error(error.response.message))))
    );
  },
  resetCharger(chargePointId: string, resetType: string): Observable<number> {
    return withAccessToken((accessToken: string) => {
      return ajax({
        url: `${appConfig.csCpoAdmin}/charge-points/${chargePointId}/reset-request`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'POST',
        body: {
          type: resetType
        }
      }).pipe(
        map((response) => {
          return response.response.time_to_wait;
        }),
        catchError((error) => throwError(new Error(error.response.message)))
      );
    });
  },

  createChargePoint(chargerPoint: NewCpoChargePoint): Observable<CpoChargePoint> {
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.csCpoAdmin}/charge-points`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'POST',
        body: toCpoCreateChargePointRequestDto(chargerPoint)
      }).pipe(catchError((error) => throwError(new Error(error.response.message))))
    );
  },

  updateEvses(chargePoint: CpoChargePoint, connectors: CpoConnector[]): Observable<void> {
    const chargerPointId = chargePoint.id;
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.csCpoAdmin}/charge-points/${chargerPointId}/evses`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'PUT',
        body: toUpdateCpoEvsesRequestDto(chargePoint.evses, connectors)
      }).pipe(catchError((error) => throwError(new Error(error.response.message))))
    );
  },

  updateConnector(tariffId: string, chargerPointId: string, evseId: string, connectorId: string): Observable<void> {
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.csCpoAdmin}/charge-points/${chargerPointId}/evses/${evseId}/connectors/${connectorId}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'PATCH',
        body: toUpdateConnectorTariffRequestDto(tariffId)
      }).pipe(catchError((error) => throwError(new Error(error.response.message))))
    );
  },

  getLogs: (chargerPointId: string, dateRange: DateRange, logType: LogType, nextToken?: string, limit?: string): Observable<ChargerLogs> => {
    const url = buildUrlWithQueryString(`${appConfig.csCpoAdmin}/charge-points/${chargerPointId}/logs`, {
      date_from: dateRange.dateTimeFrom.toISOString(),
      date_to: dateRange.dateTimeTo.toISOString(),
      next_token: nextToken,
      limit: limit,
      log_type: logType
    });
    return withAccessToken((accessToken: string) =>
      ajax({
        url,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        }
      }).pipe(
        map((response) => toChargerLogs(response.response)),
        catchError((error) => errorMessageObservable(error))
      )
    );
  },
  decommissionChargePoint(cpoChargePointId: string): Observable<void> {
    return withAccessToken((accessToken: string) =>
      ajax({
        url: `${appConfig.csCpoAdmin}/charge-points/${cpoChargePointId}`,
        headers: {
          'content-type': 'application/json',
          authorization: `Bearer ${accessToken}`
        },
        method: 'DELETE'
      }).pipe(catchError((error) => throwError(new Error(error.response.message))))
    );
  }
};

export default cpoAdminService;
