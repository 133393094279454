import {
  ChargerStatus,
  ConnectorFormat,
  ConnectorPowerType,
  ConnectorStandard,
  CpoChargePoint,
  CpoConnector,
  CpoEvse,
  EmspCharger,
  EmspConnector,
  EmspEvse
} from '../../types/charger/Charger';
import {
  CpoChargePointDto,
  CpoConnectorDto,
  CpoEvseDto,
  EmspChargerResponseDto,
  EmspChargerSummaryDto,
  EmspConnectorDto,
  EmspEvseResponseDto
} from '../../types/charger/ChargerDto';

type toEmspChargerConverter = (charger: EmspChargerSummaryDto) => EmspCharger;
type toEmspChargerFromChargerResponseDtoConverter = (charger: EmspChargerResponseDto) => EmspCharger;

export const toEmspChargerFromChargerSummaryDto: toEmspChargerConverter = (charger) => {
  const status: keyof typeof ChargerStatus = charger.status as keyof typeof ChargerStatus;
  return {
    displayName: charger.display_name,
    locationId: charger.location_id,
    locationName: charger.location_name,
    orgId: charger.org_id,
    orgName: charger.org_name,
    operator: charger.operator,
    countryCode: charger.country_code,
    partyId: charger.party_id,
    status: ChargerStatus[status],
    cpoLocationId: charger.cpo_location_id,
    locationType: charger.location_type
  };
};

export const toEmspChargerFromChargerResponseDto: toEmspChargerFromChargerResponseDtoConverter = (charger) => {
  return {
    ...toEmspChargerFromChargerSummaryDto(charger),
    evses: charger.evses.map(toEmspEvse)
  };
};

type toEmspEvseConverter = (evse: EmspEvseResponseDto) => EmspEvse;

const toEmspEvse: toEmspEvseConverter = (evse) => {
  return {
    id: evse.id,
    status: evse.status,
    cpoEvseId: evse.cpo_evse_id,
    cpoEvseUid: evse.cpo_evse_uid,
    locationId: evse.location_id,
    connectors: evse.connectors.map(toEmspConnector),
    displayName: evse.display_name
  };
};

type toEmspConnectorConverter = (connector: EmspConnectorDto) => EmspConnector;

export const toEmspConnector: toEmspConnectorConverter = (connector) => {
  return {
    id: connector.id,
    cpoConnectorId: connector.cpo_connector_id,
    standard: ConnectorStandard[connector.standard as keyof typeof ConnectorStandard],
    format: ConnectorFormat[connector.format as keyof typeof ConnectorFormat],
    powerType: ConnectorPowerType[connector.power_type as keyof typeof ConnectorPowerType],
    maxVoltage: connector.max_voltage,
    maxAmperage: connector.max_amperage,
    maxElectricPower: connector.max_electric_power,
    emspTariffOverrideId: connector.emsp_tariff_override_id,
    cpoTariffId: connector.cpo_tariff_id
  };
};
type ToCpoEvseConverter = (evse: CpoEvseDto) => CpoEvse;

export const toCpoEvse: ToCpoEvseConverter = (evseDto) => {
  return {
    id: evseDto.id,
    status: evseDto.status,
    evseId: evseDto.evse_id,
    connectors: evseDto.connectors.map(toCpoConnector)
  };
};

type ToCpoConnectorConverter = (connector: CpoConnectorDto) => CpoConnector;

export const toCpoConnector: ToCpoConnectorConverter = (connector) => {
  return {
    id: connector.id,
    ocppConnectorId: connector.ocpp_connector_id,
    standard: connector.standard,
    format: connector.format,
    powerType: connector.power_type,
    maxVoltage: connector.max_voltage,
    maxAmperage: connector.max_amperage,
    maxElectricPower: connector.max_electric_power,
    tariffId: connector.tariff_id
  };
};

type ToCpoChargePointConverter = (chargePoint: CpoChargePointDto) => CpoChargePoint;

export const toCpoChargePoint: ToCpoChargePointConverter = (chargePoint) => {
  return {
    id: chargePoint.id,
    created: chargePoint.created,
    lastUpdated: chargePoint.last_updated,
    chargeBoxSerialNumber: chargePoint.charge_box_serial_number,
    chargePointModel: chargePoint.charge_point_model,
    chargePointSerialNumber: chargePoint.charge_point_serial_number,
    chargePointVendor: chargePoint.charge_point_vendor,
    firmwareVersion: chargePoint.firmware_version,
    status: chargePoint.status,
    evses: chargePoint.evses ? chargePoint.evses.map(toCpoEvse) : [],
    anonymousChargingAllowed: chargePoint.anonymous_charging_allowed,
    locationId: chargePoint.location_id,
    ocppChargePointIdentifier: chargePoint.ocpp_charge_point_identifier,
    physicalReference: chargePoint.physical_reference
  };
};
