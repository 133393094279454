import { ChargerStatus, CpoChargePoint, CpoConnector, CpoEvse, NewCpoChargePoint } from '../../types/charger/Charger';
import {
  CpoChargePointDto,
  CpoConnectorDto,
  CpoCreateChargePointRequestDto,
  CpoEvseDto,
  UpdateConnectorTariffRequestDto,
  UpdateCpoEvsesRequestDto
} from '../../types/charger/ChargerDto';
import _ from 'lodash';

type ToCpoEvseDtoConverter = (evse: CpoEvse) => CpoEvseDto;

export const toCpoEvseDto: ToCpoEvseDtoConverter = (evse) => {
  return {
    id: evse.id,
    status: evse.status,
    evse_id: evse.evseId,
    connectors: evse.connectors.map(toCpoConnectorDto)
  };
};

type ToCpoConnectorDtoConverter = (connector: CpoConnector) => CpoConnectorDto;

export const toCpoConnectorDto: ToCpoConnectorDtoConverter = (connector) => {
  return {
    id: connector.id,
    ocpp_connector_id: connector.ocppConnectorId,
    standard: connector.standard,
    format: connector.format,
    power_type: connector.powerType,
    max_voltage: connector.maxVoltage,
    max_amperage: connector.maxAmperage,
    max_electric_power: connector.maxElectricPower,
    tariff_id: connector.tariffId
  };
};

type ToCreateChargePointRequestDtoConverter = (chargePoint: NewCpoChargePoint) => CpoCreateChargePointRequestDto;

export const toCpoCreateChargePointRequestDto: ToCreateChargePointRequestDtoConverter = (newChargePoint) => {
  return {
    status: ChargerStatus.UNAVAILABLE,
    // map each connector to an evse
    evses: newChargePoint.connectors.map((connector) => {
      return {
        connectors: [toCpoConnectorDto(connector)]
      };
    }),
    anonymous_charging_allowed: toAnonymousChargingAllowed(newChargePoint.anonymousChargingAllowed),
    location_id: newChargePoint.locationId,
    ocpp_charge_point_identifier: newChargePoint.ocppChargePointIdentifier,
    physical_reference: newChargePoint.physicalReference
  };
};

export const toAnonymousChargingAllowed = (toAnonymousChargingAllowed: boolean | string): boolean => {
  return toAnonymousChargingAllowed === 'true' || toAnonymousChargingAllowed === true;
};

export const toCpoUpdateChargePointDto = (chargePoint: Partial<CpoChargePoint>): Partial<CpoChargePointDto> => {
  return {
    anonymous_charging_allowed: _.isNil(chargePoint.anonymousChargingAllowed)
      ? undefined
      : toAnonymousChargingAllowed(chargePoint.anonymousChargingAllowed),
    ocpp_charge_point_identifier: chargePoint.ocppChargePointIdentifier
  };
};

export const toUpdateCpoEvsesRequestDto = (evses: CpoEvse[], connectors: CpoConnector[]): UpdateCpoEvsesRequestDto => {
  return {
    evses: connectors.map((connector, index) => {
      const connectorDto = toCpoConnectorDto(connector);
      const evseAtCurrentIndex = evses[index];
      const shouldUpdateEvseAtCurrentIndex = evseAtCurrentIndex != null;
      return shouldUpdateEvseAtCurrentIndex ? { id: evseAtCurrentIndex.id, connectors: [connectorDto] } : { connectors: [connectorDto] };
    })
  };
};

export const toUpdateConnectorTariffRequestDto = (tariffId: string): UpdateConnectorTariffRequestDto => {
  return {
    tariff_id : tariffId
  }
}
